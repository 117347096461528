@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:wght@500&display=swap');

body {
  background: linear-gradient(to top right, #fa7406, #f9d039);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 6rem);
}

.App {
  text-align: center;
  padding-bottom: 6.5rem;
  min-height: 100%;
  font-family: 'Montserrat', sans-serif;
}

.page-container {
  border-radius: 1rem;
  box-shadow: 0 .15rem 1rem 
    rgba(8, 8, 12, 0.1),0 .5rem 2rem 
    rgba(8, 8, 12, 0.1),0 1rem 3rem 
    rgba(8, 8, 12, 0.1)!important;
    padding: 2rem;
    margin: 2rem;
}

.navbar {
  height: 5rem;
  position: relative;
}

.nav-links, .nav-link {
  display: flex;
  justify-content: space-between;
  width: 65%;
  color: #1f1f1f !important;
  font-size: large;
  padding: 0 1.5rem 0 0;
}

.nav-links:hover, .nav-link:hover, .resume-link:hover, .project-link:hover {
  color:#4a4a4a !important;
  text-shadow: #282729;
}

.navbar-brand-link {
  padding: 0 0 0 1.5rem;
  font-family: 'Great Vibes', cursive;
  font-weight: 700;
}

.logo {
  width: 18rem;
  height: auto;
  padding: 1rem 0 1rem 0;
}

.SL {
  width: 3rem;

}

.home-img {
  width: 100%;
  height: auto;
  align-items: center;
  margin: 7.5rem 0 0 3rem;
  max-width: 25rem;
}

.Typewriter {
  padding: 1rem 0 2rem 0;
}

.Typewriter__wrapper {
  font-size: 1.5rem !important;
}

.skills-text {
  padding: 1rem 0 0 0;
}

.skills-row {
  justify-content: center;
}

.icon {
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0 .15rem 1rem 
    rgba(8, 8, 12, 0.1),0 .5rem 2rem 
    rgba(8, 8, 12, 0.1),0 1rem 3rem 
    rgba(8, 8, 12, 0.1)!important;
}

.icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 15rem;
  padding: 0 0 .5rem 0;
  margin: 0 auto;
}

.icon-container a {
  color: #ffbf1c;
  font-size: 2rem;
  margin: 0 2rem;
}

.icon-container a:hover {
  color:#fce58b;
}

.profile-img {
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
  border-style: solid;
  border-width: .15rem;
  border-color: black;
}

.about-p, h6 {
  padding: 1rem 0 0 0;
}

.profile-img.oval {
  border-radius: 50% / 70% 30%;
}

.carousel-caption {
  position: sticky !important;
  color: black !important;
}

.project-title {
  padding: .5rem;
}

.projectImage {
  width: 20rem;
  height: 15rem;
  align-items: center;
  border-radius: .5rem;
  border-color: black;
  border-style: solid;
}

.btn {
  background:rgb(0, 0, 0);
}

.form-control {
  background-color:#fdf8e2 !important;
}

.footerTxt {
  color: #fee47d;
}

.footer {
  height: 6rem;
}

@media (max-width: 767px) {
  .home-img {
    width: 7rem;
    height: 7rem;
    margin: 2rem;
  }  

  .nav-links {
    flex-direction: row !important;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0 .15rem 1rem 
      rgba(8, 8, 12, 0.1),0 .5rem 2rem 
      rgba(8, 8, 12, 0.1),0 1rem 3rem 
      rgba(8, 8, 12, 0.1)!important;
    padding: 0;
    margin-right: 1rem;
    margin-top: .25rem;
    font-size: medium;
    text-align: center;
  }

  .container {
    margin-top: .75rem;
  }
}